import { Box, Title } from "@mantine/core";
import { useSinglePrismicDocument } from "@prismicio/react";
import { Header } from "../components/Header";

function Home() {
  const [document] = useSinglePrismicDocument("home");
  const [workDocument] = useSinglePrismicDocument("work");

  return (
    <Box display="flex" className="page-container">
      <Header />
      <video id="home-reel" autoPlay muted loop>
        <source src="./videos/reel1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box display="flex" className="home-container" px="xs">
        <Box mb="lg">
          <a className="unstyled" href={workDocument?.data.vimeo.url}>
            <Title order={1}>{document?.data.title}</Title>
          </a>
        </Box>
        <a className="unstyled" href={workDocument?.data.vimeo.url}>
          <Title order={2}>{document?.data.subtitle}</Title>
        </a>
      </Box>
    </Box>
  );
}

export default Home;
